import { loadShaderProgram } from "../shader";
import {
  createQuadMesh,
  enablePositionVertexAttribute,
  enableTexCoordsVertexAttribute,
} from "../mesh";

const fragmentShaderSource = `
    precision mediump float;

    uniform sampler2D uTexture;

    varying vec2 vTexCoords;
    
    vec3 colorFilter = vec3(0.975, 0.95, 0.925);

    void main() {
        vec3 originalColor = texture2D(uTexture, vTexCoords).rgb;
        vec3 grayColor = vec3((originalColor.r + originalColor.g + originalColor.b) / 3.0);
        vec3 invertedColor =  vec3(1.0) - originalColor;
        vec3 sepiaColor = vec3(0.975, 0.95, 0.925);
        
        gl_FragColor = vec4(sepiaColor * mix(grayColor, invertedColor, 0.025), 1.0);
    }
`;

const vertexShaderSource = `
    attribute vec2 aPosition;
    attribute vec2 aTexCoords;

    varying vec2 vTexCoords;
    
    uniform float uCanvasAspect;
    uniform float uVideoAspect;

    void main() {
        float aspectRatio = uCanvasAspect / uVideoAspect;
        float inverseAspectRatio = 1.0 / aspectRatio;
        
        if(aspectRatio >= 1.0) {
          float remainingAspect = 1.0 - inverseAspectRatio;
          vTexCoords = aTexCoords * vec2(1.0, inverseAspectRatio) + vec2(0.0, remainingAspect / 2.0);
        } else {
          float remainingAspect = 1.0 - aspectRatio;
          vTexCoords = aTexCoords * vec2(aspectRatio, 1.0) + vec2(remainingAspect / 2.0, 0.0);
        }
        
        gl_Position = vec4(aPosition, 1.0, 1.0);
    }
`;

export interface GrayBackgroundShader {
  render: (texture: WebGLTexture, textureAspect: number) => void;
}

export const createGrayBackgroundShader = (
  gl: WebGLRenderingContext,
): GrayBackgroundShader => {
  // Load shader program
  const shaderProgram = loadShaderProgram(
    gl,
    vertexShaderSource,
    fragmentShaderSource,
  );

  const shaderLocations = {
    attrib: {
      position: gl.getAttribLocation(shaderProgram, "aPosition"),
      texCoords: gl.getAttribLocation(shaderProgram, "aTexCoords"),
    },
    uniform: {
      texture: gl.getUniformLocation(shaderProgram, "uTexture"),
      videoAspect: gl.getUniformLocation(shaderProgram, "uVideoAspect"),
      canvasAspect: gl.getUniformLocation(shaderProgram, "uCanvasAspect"),
    },
  };

  // Create quad mesh
  const quadMesh = createQuadMesh(gl);

  const render = (texture: WebGLTexture, videoAspect: number) => {
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    // Clear back buffer
    gl.clearDepth(1);
    gl.clearColor(0, 0, 0, 1);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    // Draw quad
    gl.useProgram(shaderProgram);
    enablePositionVertexAttribute(
      gl,
      shaderLocations.attrib.position,
      quadMesh,
    );
    enableTexCoordsVertexAttribute(
      gl,
      shaderLocations.attrib.texCoords,
      quadMesh,
    );
    // Bind video texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.uniform1i(shaderLocations.uniform.texture, 0);

    // Aspect
    gl.uniform1f(
      shaderLocations.uniform.canvasAspect,
      gl.canvas.width / gl.canvas.height,
    );
    gl.uniform1f(shaderLocations.uniform.videoAspect, videoAspect);

    gl.drawArrays(gl.TRIANGLES, 0, 6);

    // Clean up
    gl.bindTexture(gl.TEXTURE_2D, null);
  };

  return {
    render,
  };
};
