import React, { useEffect, useState } from "react";
import { HomeRoute } from "./route/homeRoute";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { routes } from "./route";
import { PrivacyPolicyRoute } from "./route/privacyPolicyRoute";
import { TopButtonToolbar } from "./component/TopButtonToolbar";
import { FullScreenButton } from "./component/FullScreenButton";
import { ImprintRoute } from "./route/imprintRoute";
import { AttributionsRoute } from "./route/attributionsRoute";
import { DisclaimerRoute } from "./route/disclaimerRoute";
import { displayBanner } from "./ads";
import { PuzzleRoute } from "./route/puzzleRoute";
import { ParticleCanvas } from "./component/ParticleCanvas";

const router = createHashRouter([
  {
    path: routes.home.path,
    Component: HomeRoute,
  },
  {
    path: routes.puzzle.path,
    Component: PuzzleRoute,
  },
  {
    path: routes.privacyPolicy.path,
    Component: PrivacyPolicyRoute,
  },
  {
    path: routes.imprint.path,
    Component: ImprintRoute,
  },
  {
    path: routes.attributions.path,
    Component: AttributionsRoute,
  },
  {
    path: routes.disclaimer.path,
    Component: DisclaimerRoute,
  },
]);

function App() {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    // Imitate full screen
    window.scrollTo(0, 1);

    // Load vignette
    displayBanner();
  }, []);

  return (
    <div
      ref={setElement}
      style={{
        height: "100vh",
        overflow: "clip",
        backgroundImage: 'url("/images/background.jpg")',
        backgroundSize: "cover",
      }}
    >
      <TopButtonToolbar>
        {element && <FullScreenButton element={element} />}
      </TopButtonToolbar>
      <div style={{ height: "100%", overflow: "auto" }}>
        <RouterProvider router={router} />
      </div>
      <ParticleCanvas />
    </div>
  );
}

export default App;
