import { loadShaderProgram } from "../shader";
import {
  createQuadMesh,
  enablePositionVertexAttribute,
  enableTexCoordsVertexAttribute,
} from "../mesh";
import { createFrameBuffer } from "../frame-buffer";
import { createFrameBufferTexture, loadTexture } from "../texture";
import { Puzzle, PuzzlePiece } from "../../puzzle";

const fragmentShaderSource = `
    precision mediump float;

    uniform sampler2D uShapeTexture;
    
    varying vec2 vTexCoords;
    
    uniform vec4 uColor;

    void main() {
        vec4 textureColor = texture2D(uShapeTexture, vTexCoords);
        
        if(textureColor.r < 0.5) {
          discard;
        }
  
        gl_FragColor = uColor;
    }
`;

const vertexShaderSource = `
    attribute vec2 aPosition;
    attribute vec2 aTexCoords;

    varying vec2 vTexCoords;

    void main() {
        vTexCoords = aTexCoords;
        gl_Position = vec4(aPosition, 1.0, 1.0);
    }
`;

const pieceToKey = (puzzlePiece: PuzzlePiece) =>
  `${puzzlePiece.targetRow}-${puzzlePiece.targetColumn}`;

export interface Page {
  pageKey: string;
  pageFrameBuffer: WebGLFramebuffer;
  pageTexture: WebGLTexture;
}

export interface Mask {
  x: number;
  y: number;
  width: number;
  height: number;
  page: Page;
}

export interface JigsawMaskShader {
  preparePageTextures: (puzzle: Puzzle) => Promise<void>;
  getMask: (puzzlePiece: PuzzlePiece) => Mask;
}

const SIZE = 256;
const CENTER = SIZE / 2;
const BASE_SQUARE_SIZE = SIZE * 0.605;
const SIZE_FEMALE = SIZE * 0.18359375;
const SIZE_MALE = SIZE * 0.1875;
const HALF_SIZE_FEMALE = SIZE_FEMALE / 2;
const HALF_SIZE_MALE = SIZE_MALE / 2;
const DISPLACEMENT_MALE = SIZE_MALE * 0.25;
const DISPLACEMENT_FEMALE = SIZE_MALE * 0.875;

export const MASKS_PER_PAGE = 8;
const PAGE_SIZE = SIZE * MASKS_PER_PAGE;

const getPageKey = (pageX: number, pageY: number) => `${pageX}-${pageY}`;
const getMaskKey = (puzzlePiece: PuzzlePiece) =>
  `${puzzlePiece.targetColumn}-${puzzlePiece.targetRow}`;

export const createJigsawMaskShader = (
  gl: WebGLRenderingContext,
): JigsawMaskShader => {
  const pages: Record<string, Page> = {};
  const masks: Record<string, Mask> = {};

  // Load shader program
  const shaderProgram = loadShaderProgram(
    gl,
    vertexShaderSource,
    fragmentShaderSource,
  );

  const shaderLocations = {
    attrib: {
      position: gl.getAttribLocation(shaderProgram, "aPosition"),
      texCoords: gl.getAttribLocation(shaderProgram, "aTexCoords"),
    },
    uniform: {
      shapeTexture: gl.getUniformLocation(shaderProgram, "uShapeTexture"),
      color: gl.getUniformLocation(shaderProgram, "uColor"),
    },
  };

  const shapeColor = (color: "black" | "white") => {
    if (color === "white") {
      gl.uniform4f(shaderLocations.uniform.color, 1.0, 1.0, 1.0, 1.0);
    } else {
      gl.uniform4f(shaderLocations.uniform.color, 0.0, 0.0, 0.0, 1.0);
    }
  };

  // Create quad mesh
  const quadMesh = createQuadMesh(gl);

  const providePage = (puzzlePiece: PuzzlePiece): Page => {
    const pageX = Math.floor(puzzlePiece.targetColumn / MASKS_PER_PAGE);
    const pageY = Math.floor(puzzlePiece.targetRow / MASKS_PER_PAGE);
    const pageKey = getPageKey(pageX, pageY);

    if (pageKey in pages) {
      return pages[pageKey];
    }

    const frameBuffer = createFrameBuffer(gl);

    if (
      !frameBuffer ||
      gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE
    ) {
      throw new Error("No frame buffer");
    }

    const frameBufferTexture = createFrameBufferTexture(
      gl,
      PAGE_SIZE,
      PAGE_SIZE,
    );

    // Initialize texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      frameBufferTexture,
      0,
    );

    gl.viewport(0, 0, PAGE_SIZE, PAGE_SIZE);

    // Clear back buffer
    gl.clearDepth(1);
    gl.clearColor(0, 0, 0, 1);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    gl.bindBuffer(gl.FRAMEBUFFER, null);

    const page = {
      pageKey,
      pageFrameBuffer: frameBuffer,
      pageTexture: frameBufferTexture,
    };

    pages[pageKey] = page;

    return page;
  };

  const prepareMasks = async (puzzle: Puzzle) => {
    const squareTexture = await loadTexture(gl, "/images/shape_square.png");
    const circleTexture = await loadTexture(gl, "/images/shape_circle.png");

    const renderMaskToPage = (puzzlePiece: PuzzlePiece) => {
      const page = providePage(puzzlePiece);

      gl.activeTexture(gl.TEXTURE0);
      gl.bindFramebuffer(gl.FRAMEBUFFER, page.pageFrameBuffer);

      const offsetX = (puzzlePiece.targetColumn % MASKS_PER_PAGE) * SIZE;
      const offsetY = (puzzlePiece.targetRow % MASKS_PER_PAGE) * SIZE;

      const maskViewPort = (
        x: number,
        y: number,
        width: number,
        height: number,
      ) => {
        gl.viewport(offsetX + x, offsetY + y, width, height);
      };

      const renderShape = (
        x: number,
        y: number,
        width: number,
        height: number,
      ) => {
        maskViewPort(x, y, width, height);
        gl.drawArrays(gl.TRIANGLES, 0, 6);
      };

      maskViewPort(0, 0, SIZE, SIZE);

      // Draw quad
      gl.useProgram(shaderProgram);
      gl.bindBuffer(gl.ARRAY_BUFFER, quadMesh.vertexBuffer);
      enablePositionVertexAttribute(
        gl,
        shaderLocations.attrib.position,
        quadMesh,
      );
      enableTexCoordsVertexAttribute(
        gl,
        shaderLocations.attrib.texCoords,
        quadMesh,
      );
      // Bind shape texture
      gl.activeTexture(gl.TEXTURE0);
      gl.uniform1i(shaderLocations.uniform.shapeTexture, 0);

      // Base square
      gl.bindTexture(gl.TEXTURE_2D, squareTexture);
      shapeColor("white");
      renderShape(
        (SIZE - BASE_SQUARE_SIZE) / 2,
        (SIZE - BASE_SQUARE_SIZE) / 2,
        BASE_SQUARE_SIZE,
        BASE_SQUARE_SIZE,
      );

      // Nubbies
      gl.bindTexture(gl.TEXTURE_2D, circleTexture);

      // Top nubby
      if (puzzlePiece.nubbies.top?.type === "male") {
        shapeColor("white");
        renderShape(
          CENTER -
            HALF_SIZE_MALE +
            SIZE_MALE * puzzlePiece.nubbies.top.offset.x,
          SIZE -
            SIZE_MALE -
            DISPLACEMENT_MALE +
            SIZE_MALE * puzzlePiece.nubbies.top.offset.y,
          SIZE_MALE,
          SIZE_MALE,
        );
      } else if (puzzlePiece.nubbies.top?.type === "female") {
        shapeColor("black");
        renderShape(
          CENTER -
            HALF_SIZE_FEMALE +
            SIZE_MALE * puzzlePiece.nubbies.top.offset.x,
          SIZE -
            SIZE_FEMALE -
            DISPLACEMENT_FEMALE +
            SIZE_MALE * puzzlePiece.nubbies.top.offset.y,
          SIZE_FEMALE,
          SIZE_FEMALE,
        );
      }

      // Bottom nubby
      if (puzzlePiece.nubbies.bottom?.type === "male") {
        shapeColor("white");
        renderShape(
          CENTER -
            HALF_SIZE_MALE +
            SIZE_MALE * puzzlePiece.nubbies.bottom.offset.x,
          DISPLACEMENT_MALE + SIZE_MALE * puzzlePiece.nubbies.bottom.offset.y,
          SIZE_MALE,
          SIZE_MALE,
        );
      } else if (puzzlePiece.nubbies.bottom?.type === "female") {
        shapeColor("black");
        renderShape(
          CENTER -
            HALF_SIZE_FEMALE +
            SIZE_MALE * puzzlePiece.nubbies.bottom.offset.x,
          DISPLACEMENT_FEMALE + SIZE_MALE * puzzlePiece.nubbies.bottom.offset.y,
          SIZE_FEMALE,
          SIZE_FEMALE,
        );
      }

      // Left nubby
      if (puzzlePiece.nubbies.left?.type === "male") {
        shapeColor("white");
        renderShape(
          DISPLACEMENT_MALE + SIZE_MALE * puzzlePiece.nubbies.left.offset.x,
          CENTER -
            HALF_SIZE_MALE +
            SIZE_MALE * puzzlePiece.nubbies.left.offset.y,
          SIZE_MALE,
          SIZE_MALE,
        );
      } else if (puzzlePiece.nubbies.left?.type === "female") {
        shapeColor("black");
        renderShape(
          DISPLACEMENT_FEMALE + SIZE_MALE * puzzlePiece.nubbies.left.offset.x,
          CENTER -
            HALF_SIZE_FEMALE +
            SIZE_MALE * puzzlePiece.nubbies.left.offset.y,
          SIZE_FEMALE,
          SIZE_FEMALE,
        );
      }

      // Right nubby
      if (puzzlePiece.nubbies.right?.type === "male") {
        shapeColor("white");
        renderShape(
          SIZE -
            SIZE_MALE -
            DISPLACEMENT_MALE +
            SIZE_MALE * puzzlePiece.nubbies.right.offset.x,
          CENTER -
            HALF_SIZE_MALE +
            SIZE_MALE * puzzlePiece.nubbies.right.offset.y,
          SIZE_MALE,
          SIZE_MALE,
        );
      } else if (puzzlePiece.nubbies.right?.type === "female") {
        shapeColor("black");
        renderShape(
          SIZE -
            SIZE_FEMALE -
            DISPLACEMENT_FEMALE +
            SIZE_MALE * puzzlePiece.nubbies.right.offset.x,
          CENTER -
            HALF_SIZE_FEMALE +
            SIZE_MALE * puzzlePiece.nubbies.right.offset.y,
          SIZE_FEMALE,
          SIZE_FEMALE,
        );
      }

      // Clean up
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    };

    puzzle.pieces.forEach(renderMaskToPage);
  };

  const getMask: JigsawMaskShader["getMask"] = (puzzlePiece) => {
    const maskKey = getMaskKey(puzzlePiece);

    if (maskKey in masks) {
      return masks[maskKey];
    }

    const page = providePage(puzzlePiece);

    const maskSize = 1 / MASKS_PER_PAGE;

    const mask: Mask = {
      x: (puzzlePiece.targetColumn % MASKS_PER_PAGE) * maskSize,
      y: (puzzlePiece.targetRow % MASKS_PER_PAGE) * maskSize,
      width: maskSize,
      height: maskSize,
      page,
    };

    masks[maskKey] = mask;

    return mask;
  };

  return {
    preparePageTextures: prepareMasks,
    getMask,
  };
};
