import { isProduction } from "./environment";

const interstitialIds: string[] = ["7544707", "7544710", "7544716"];
let interstitialIndex = 0;

export const INTERSTITIAL_PROBABILITY = 0.15;

export const interstitialAvailable = () => {
  return isProduction() && interstitialIndex < interstitialIds.length;
};

export const displayInterstitial = () => {
  if (Math.random() > INTERSTITIAL_PROBABILITY) {
    return;
  }

  if (!interstitialAvailable()) {
    console.debug("Ads :: Skipping interstitial");
    return;
  }

  console.debug("Ads :: Display interstitial", interstitialIndex);
  const element = document.createElement("script");
  element.async = true;
  element.src = `//thubanoa.com/1?z=${interstitialIds[interstitialIndex]}`;
  document.head.append(element);
  interstitialIndex += 1;
};

let vignetteLoaded = false;

export const displayBanner = () => {
  if (vignetteLoaded || !isProduction()) {
    console.debug("Ads :: Skipping banner");
    return;
  }

  console.debug("Ads :: Display banner");

  const element = document.createElement("script");
  element.innerText = `(function(d,z,s){s.src='https://'+d+'/400/'+z;try{(document.body||document.documentElement).appendChild(s)}catch(e){}})('rndhaunteran.com',7544679,document.createElement('script'))`;
  element.setAttribute("data-cfasync", "false");
  element.setAttribute("type", "text/javascript");
  document.head.append(element);
  vignetteLoaded = true;
};
