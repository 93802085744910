import { createNoise2D } from "simplex-noise";

export type PuzzlePieceSize = "small" | "medium" | "large";
export type NubbyType = "male" | "female";

interface XY {
  x: number;
  y: number;
}

export interface Nubby {
  type: NubbyType;
  offset: XY;
  scale: XY;
}

export interface Nubbies {
  top: Nubby | undefined;
  bottom: Nubby | undefined;
  left: Nubby | undefined;
  right: Nubby | undefined;
}

export interface PuzzlePiece {
  canvasX: number;
  canvasY: number;
  targetColumn: number;
  targetRow: number;
  locked: boolean;
  visible: boolean;
  nubbies: Nubbies;
}

export interface Puzzle {
  pieces: PuzzlePiece[];
  columns: number;
  rows: number;
}

export const createQuadPuzzle = (
  columns: number,
  rows: number,
  gl: WebGLRenderingContext,
): Puzzle => {
  const nubbyTypeNoise = createNoise2D();
  const nubbyOffsetXNoise = createNoise2D();
  const nubbyOffsetYNoise = createNoise2D();
  const nubbyScaleXNoise = createNoise2D();
  const nubbyScaleYNoise = createNoise2D();

  const generateNubbyType = (
    nubbyColumn: number,
    nubbyRow: number,
    inverse: boolean,
  ): NubbyType => {
    if (nubbyRow <= 0.9) {
    }

    const noiseValue = nubbyTypeNoise(nubbyColumn, nubbyRow);
    const effectiveValue = inverse ? noiseValue * -1 : noiseValue;
    return effectiveValue >= 0.0 ? "male" : "female";
  };

  const generateNubbyOffset = (column: number, row: number): XY => ({
    x: nubbyOffsetXNoise(column, row) * 0.2,
    y: nubbyOffsetYNoise(column, row) * 0.2,
  });

  const generateNubbyScale = (column: number, row: number): XY => ({
    x: 0.8 + nubbyScaleXNoise(column, row) * 0.4,
    y: 0.8 + nubbyScaleYNoise(column, row) * 0.4,
  });

  const pieceWidth = gl.canvas.width / columns;
  const pieceHeight = gl.canvas.height / rows;

  const generateNubby = (
    inverse: boolean,
    nubbyColumn: number,
    nubbyRow: number,
  ): Nubby => ({
    type: generateNubbyType(nubbyColumn, nubbyRow, inverse),
    offset: generateNubbyOffset(nubbyColumn, nubbyRow),
    scale: generateNubbyScale(nubbyColumn, nubbyRow),
  });

  const pieces = Array.from({ length: columns * rows }).map(
    (_, index): PuzzlePiece => {
      const row = Math.floor(index / columns);
      const column = index - row * columns;

      return {
        targetColumn: column,
        targetRow: row,
        canvasX:
          pieceWidth / 2 + Math.random() * (gl.canvas.width - pieceWidth),
        canvasY:
          pieceHeight / 2 + Math.random() * (gl.canvas.height - pieceHeight),
        locked: false,
        visible: true,
        nubbies: {
          top:
            row === rows - 1
              ? undefined
              : generateNubby(false, column + 0.5, row + 1),
          bottom:
            row === 0 ? undefined : generateNubby(true, column + 0.5, row),
          left:
            column === 0 ? undefined : generateNubby(false, column, row + 0.5),
          right:
            column === columns - 1
              ? undefined
              : generateNubby(true, column + 1, row + 0.5),
        },
      };
    },
  );

  return {
    pieces,
    columns,
    rows,
  };
};

export const pieceHeightInPx = (puzzle: Puzzle, gl: WebGLRenderingContext) =>
  gl.canvas.height / puzzle.rows;
export const pieceWidthInPx = (puzzle: Puzzle, gl: WebGLRenderingContext) =>
  gl.canvas.width / puzzle.columns;

export const pieceXToClipSpaceX = (
  piece: PuzzlePiece,
  gl: WebGLRenderingContext,
) => (piece.canvasX / gl.canvas.width) * 2 - 1;

export const pieceYToClipSpaceY = (
  piece: PuzzlePiece,
  gl: WebGLRenderingContext,
) => (piece.canvasY / gl.canvas.height) * 2 - 1;
