
export const loadShaderProgram = (gl: WebGLRenderingContext, vertexShaderSource: string, fragmentShaderSource: string): WebGLProgram => {
  // Load vertex and fragment shader
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);

  // Create shader program
  const shaderProgram = gl.createProgram();

  if (!shaderProgram) {
    throw new Error(`Failed to create webgl shader program`)
  }

  // Link shaders to program
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);


  if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
    throw new Error(`Failed to create webgl shader program`)
  }

  return shaderProgram;
}

const loadShader = (gl: WebGLRenderingContext, type: GLenum, source: string) => {
  // Create shader
  const shader = gl.createShader(type);

  if (!shader) {
    throw new Error(`Failed to create webgl shader of type ${type}`)
  }

  // Compile shader
  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    const log = gl.getShaderInfoLog(shader)
    gl.deleteShader(shader);

    console.error("GL :: Shader", type, source, log);

    throw new Error(`Failed to compile webgl shader`)
  }

  return shader;
}