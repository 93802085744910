import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "./index";
import { ShareButtons } from "../component/ShareButtons";
import { PuzzlePieceSize } from "../puzzle";
import { displayInterstitial } from "../ads";
import { FilePond } from "react-filepond";

import "filepond/dist/filepond.min.css";
import { loadSettings, mergeSettings } from "../persisted-settings";
import Image from "react-bootstrap/Image";

const CUSTOM_VIDEO_URL = "file://";
const DASH_URL = "dash://";

const DASH_PUZZLE_SOURCE: PuzzleSource = {
  url: DASH_URL,
  name: "dash",
};

const puzzleSources: PuzzleSource[] = [
  {
    name: "animals_1",
    url: "/videos/animals_1.mkv",
  },
  {
    name: "animals_2",
    url: "/videos/animals_2.webm",
  },
  {
    name: "city_life_1",
    url: "/videos/city_life_1.webm",
  },
  {
    name: "fire_place_1",
    url: "/videos/fire_place_1.mkv",
  },
  {
    name: "forest_1",
    url: "/videos/forest_1.mkv",
  },
  {
    name: "nature_1",
    url: "/videos/nature_1.mkv",
  },
  {
    name: "underwater_1",
    url: "/videos/underwater_1.mkv",
  },
  {
    name: "underwater_2",
    url: "/videos/underwater_2.mkv",
  },
  {
    name: "underwater_3",
    url: "/videos/underwater_3.mkv",
  },
  {
    name: "snow_fall_1",
    url: "/videos/snow_fall_1.mkv",
  },
  {
    name: "space_1",
    url: "/videos/space_1.mkv",
  },
  {
    name: "space_2",
    url: "/videos/space_2.webm",
  },
  {
    name: "waterfalls_1",
    url: "/videos/waterfalls_1.webm",
  },
  DASH_PUZZLE_SOURCE,
  {
    name: "custom",
    url: CUSTOM_VIDEO_URL,
  },
];

interface PuzzleSource {
  name: string;
  url: string;
}

export const HomeRoute = () => {
  const [puzzlePieceSize, setPuzzlePieceSize] = useState<PuzzlePieceSize>(
    () => loadSettings().puzzlePieceSize ?? "large",
  );
  const [videoSelectUrl, setVideoSelectUrl] = useState<string>(
    () => loadSettings().videoSelectUrl ?? puzzleSources[0].url,
  );
  const [customVideoUrl, setCustomVideoUrl] = useState<string | null>(null);

  const navigate = useNavigate();
  const intl = useIntl();

  const playVideo = (videoUrl: string) => {
    navigate(routes.puzzle.render(videoUrl, puzzlePieceSize));
    displayInterstitial();
  };

  return (
    <Container
      className={"d-flex flex-column justify-content-center align-items-center"}
      style={{ minHeight: "100%" }}
    >
      <div className={"d-flex gap-2 align-items-center"}>
        <Image
          src={"/images/logo.png"}
          style={{
            width: "6rem",
            height: "6rem",
          }}
        />
        <h1
          className={"mt-3"}
          style={{ textAlign: "center", display: "inline" }}
        >
          <FormattedMessage id={"game.title"} />
        </h1>
      </div>

      <Row style={{ width: "100%" }}>
        <Col />
        <Col lg={6}>
          <Form>
            <div className={"mt-3"}>
              <Form.Label htmlFor={"videoMotive"}>
                <FormattedMessage id={"video-motive"} />
              </Form.Label>
              <Form.Select
                id={"videoMotive"}
                size={"lg"}
                value={videoSelectUrl}
                onChange={(e) => {
                  const value = e.target.value;
                  mergeSettings({ videoSelectUrl: value });
                  setVideoSelectUrl(value);

                  if (value === CUSTOM_VIDEO_URL) {
                    setCustomVideoUrl(null);
                  } else {
                    setVideoSelectUrl(value);
                  }
                }}
                style={{ height: "4rem" }}
              >
                {puzzleSources.map((pS) => (
                  <option
                    key={pS.url}
                    value={pS.url}
                    disabled={pS.url === DASH_URL}
                  >
                    <FormattedMessage id={`videos.${pS.name}`} />
                  </option>
                ))}
              </Form.Select>
            </div>
            {videoSelectUrl === CUSTOM_VIDEO_URL && (
              <div className={"mt-3 text-center"}>
                <FilePond
                  allowMultiple={false}
                  labelIdle={intl.formatMessage({
                    id: "upload.own.video.hint",
                  })}
                  acceptedFileTypes={["video/*"]}
                  onupdatefiles={(files) => {
                    console.debug("Custom video", files);
                    setCustomVideoUrl(
                      files.length > 0
                        ? URL.createObjectURL(files[0].file)
                        : null,
                    );
                  }}
                  credits={false}
                />
                <small style={{ color: "darkgray" }}>
                  <FormattedMessage id={"upload.own.video.privacy.hint"} />
                </small>
              </div>
            )}

            <div className={"mt-3"}>
              <Form.Label htmlFor={"puzzleSizeSelect"}>
                <FormattedMessage id={"piece-size"} />
              </Form.Label>
              <Form.Select
                id={"puzzleSizeSelect"}
                size={"lg"}
                value={puzzlePieceSize}
                onChange={(e) => {
                  const puzzlePieceSize = e.target.value as PuzzlePieceSize;
                  mergeSettings({ puzzlePieceSize });
                  return setPuzzlePieceSize(puzzlePieceSize);
                }}
                style={{ height: "4rem" }}
              >
                <option value={"large"}>
                  <FormattedMessage id={"piece-size.large"} />
                </option>
                <option value={"medium"}>
                  <FormattedMessage id={"piece-size.medium"} />
                </option>
                <option value={"small"}>
                  <FormattedMessage id={"piece-size.small"} />
                </option>
              </Form.Select>
            </div>
            <Button
              size={"lg"}
              style={{ width: "100%", height: "4rem" }}
              className={"mt-4"}
              variant="primary"
              disabled={
                videoSelectUrl === CUSTOM_VIDEO_URL && customVideoUrl === null
              }
              onClick={() => {
                if (videoSelectUrl === CUSTOM_VIDEO_URL) {
                  if (customVideoUrl) {
                    playVideo(customVideoUrl);
                  }
                } else {
                  playVideo(videoSelectUrl);
                }
              }}
            >
              <FormattedMessage id={"play"} />
            </Button>
          </Form>
        </Col>
        <Col />
      </Row>

      <small
        style={{ textAlign: "center", display: "block", width: "100%" }}
        className={"text-muted mt-4"}
      >
        <FormattedMessage id={"use.fullscreen.on.mobile"} />
      </small>
      <div style={{ width: "100%" }} className={"mt-3"}>
        <ShareButtons />
      </div>
      <div
        style={{ width: "100%" }}
        className={"d-flex justify-content-center gap-2 mt-5 mb-3"}
      >
        <Button
          size={"sm"}
          variant={"link"}
          onClick={() => navigate(routes.privacyPolicy.render())}
        >
          <FormattedMessage id={"privacy.policy"} />
        </Button>
        <Button
          size={"sm"}
          variant={"link"}
          onClick={() => navigate(routes.attributions.render())}
        >
          <FormattedMessage id={"attributions"} />
        </Button>
        <Button
          size={"sm"}
          variant={"link"}
          onClick={() => navigate(routes.imprint.render())}
        >
          <FormattedMessage id={"imprint"} />
        </Button>
        <Button
          size={"sm"}
          variant={"link"}
          onClick={() => navigate(routes.disclaimer.render())}
        >
          <FormattedMessage id={"disclaimer"} />
        </Button>
      </div>
    </Container>
  );
};
