import { To } from "@remix-run/router/history";

export interface Route {
  path: string;
  render: (...params: any[]) => To;
}

export const routes = {
  home: {
    path: "/",
    render: () => "/",
  },
  puzzle: {
    path: "/puzzle/:puzzleSize/:videoUrl",
    render: (videoUrl: string, puzzleSize) =>
      `/puzzle/${puzzleSize}/${encodeURIComponent(videoUrl)}/`,
  },
  privacyPolicy: {
    path: "/privacy-policy",
    render: () => "/privacy-policy",
  },
  imprint: {
    path: "/imprint",
    render: () => "/imprint",
  },
  attributions: {
    path: "/attributions",
    render: () => "/attributions",
  },
  disclaimer: {
    path: "/disclaimer",
    render: () => "/disclaimer",
  },
} as const satisfies Record<string, Route>;
