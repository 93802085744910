import { Container } from "react-bootstrap";
import React, { useState } from "react";
import { PuzzleCanvas } from "../component/PuzzleCanvas";
import { useParams } from "react-router-dom";
import { PuzzlePieceSize } from "../puzzle";

export const PuzzleRoute = () => {
  const { videoUrl, puzzleSize } = useParams();
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null,
  );

  if (!videoUrl || !puzzleSize) {
    throw new Error("Invalid video url or size");
  }

  return (
    <Container
      className={"d-flex flex-column justify-content-center align-items-center"}
      style={{ height: "100%" }}
    >
      <div style={{ width: "100vw", height: "100vh", overflow: "clip" }}>
        {videoElement && (
          <PuzzleCanvas
            videoElement={videoElement}
            puzzleSize={puzzleSize as PuzzlePieceSize}
          />
        )}
      </div>
      <video
        ref={(videoElement) => {
          if (videoElement) {
            videoElement.volume = 0.5;
          }

          setVideoElement(videoElement);
        }}
        crossOrigin={"anonymous"}
        autoPlay={true}
        loop={true}
        style={{
          width: "10rem",
          height: "10rem",
          display: "none",
        }}
      >
        <source src={videoUrl} />
      </video>
    </Container>
  );
};
