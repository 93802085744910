import { FormattedMessage, useIntl } from "react-intl";
import Image from "react-bootstrap/Image";

const logoStyle = {
  width: "2rem",
  height: "2rem",
};

export const ShareButtons = () => {
  const intl = useIntl();

  return (
    <div>
      <p style={{ textAlign: "center" }}>
        <FormattedMessage id={"share-on"} />
      </p>
      <div className={"d-flex justify-content-center gap-2"}>
        <a
          target={"_blank"}
          rel={"noreferrer"}
          href={intl.formatMessage({ id: "share-on.whatsapp.url" })}
        >
          <Image
            src={"/images/whatsapp-logo.svg"}
            alt={intl.formatMessage({ id: "share-on.whatsapp" })}
            style={logoStyle}
          />
        </a>
        <a
          target={"_blank"}
          rel={"noreferrer"}
          href={intl.formatMessage({ id: "share-on.x.url" })}
        >
          <Image
            src={"/images/x-logo.svg"}
            alt={intl.formatMessage({ id: "share-on.x" })}
            style={logoStyle}
          />
        </a>
        <a
          target={"_blank"}
          rel={"noreferrer"}
          href={intl.formatMessage({ id: "share-on.facebook.url" })}
        >
          <Image
            src={"/images/facebook-logo.png"}
            alt={intl.formatMessage({ id: "share-on.facebook" })}
            style={logoStyle}
          />
        </a>
        <a
          target={"_blank"}
          rel={"noreferrer"}
          href={intl.formatMessage({ id: "share-on.reddit.url" })}
        >
          <Image
            src={"/images/reddit-logo.svg"}
            alt={intl.formatMessage({ id: "share-on.reddit" })}
            style={logoStyle}
          />
        </a>
      </div>
    </div>
  );
};
