export const groupByProperty = <T, K>(
  array: T[],
  getKey: (value: T) => K,
): Map<K, T[]> => {
  return array.reduce((accumulator: Map<K, T[]>, currentValue) => {
    const key = getKey(currentValue);
    if (!accumulator.has(key)) {
      accumulator.set(key, []);
    }
    accumulator.get(key)?.push(currentValue);
    return accumulator;
  }, new Map());
};
