import { PuzzlePieceSize } from "./puzzle";

const KEY = "localStore";

export interface PersistedSettings {
  videoSelectUrl: string;
  puzzlePieceSize: PuzzlePieceSize;
  drawGrayBackground: boolean;
  drawOutlines: boolean;
}

export const mergeSettings = (settings: Partial<PersistedSettings>) => {
  const oldSettings = loadSettings();
  const mergedSettings = {
    ...oldSettings,
    ...settings,
  };
  window.localStorage.setItem(KEY, JSON.stringify(mergedSettings));
};

export const loadSettings = (): Partial<PersistedSettings> => {
  const item = window.localStorage.getItem(KEY);

  if (!item) {
    return {};
  }

  try {
    return JSON.parse(item);
  } catch (e) {
    console.error("Persisted Settings :: Failed to load settings", e);
    return {};
  }
};
