const isPowerOf2 = (value: number) => (value & (value - 1)) === 0;

export const createFrameBufferTexture = (
  gl: WebGLRenderingContext,
  width: number,
  height: number,
): WebGLTexture => {
  const texture = gl.createTexture();

  if (!texture) {
    throw new Error("Failed to create web gl texture");
  }

  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Because video has to be download over the internet
  // they might take a moment until it's ready so
  // put a single pixel in the texture so we can
  // use it immediately.
  const level = 0;
  const internalFormat = gl.RGBA;
  const border = 0;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  gl.texImage2D(
    gl.TEXTURE_2D,
    level,
    internalFormat,
    width,
    height,
    border,
    srcFormat,
    srcType,
    null,
  );

  // Turn off mips and set wrapping to clamp to edge to it
  // will work regardless of the dimensions of the video.
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

  return texture;
};

export const createTexture = (gl: WebGLRenderingContext): WebGLTexture => {
  const texture = gl.createTexture();

  if (!texture) {
    throw new Error("Failed to create web gl texture");
  }

  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Because video has to be download over the internet
  // they might take a moment until it's ready so
  // put a single pixel in the texture so we can
  // use it immediately.
  const level = 0;
  const internalFormat = gl.RGBA;
  const width = 1;
  const height = 1;
  const border = 0;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  const pixel = new Uint8Array([0, 0, 0, 255]); // Black
  gl.texImage2D(
    gl.TEXTURE_2D,
    level,
    internalFormat,
    width,
    height,
    border,
    srcFormat,
    srcType,
    pixel,
  );

  // Turn off mips and set wrapping to clamp to edge to it
  // will work regardless of the dimensions of the video.
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

  return texture;
};

export const loadTexture = (
  gl: WebGLRenderingContext,
  textureUrl: string,
): Promise<WebGLTexture> => {
  const texture = createTexture(gl);

  // Use a transparent one-pixel texture while the real texture is loading
  gl.bindTexture(gl.TEXTURE_2D, texture);

  const pixels = new Uint8Array([0, 0, 0, 0]); // Transparent pixel

  gl.texImage2D(
    gl.TEXTURE_2D,
    0, // Mipmap level
    gl.RGBA, // Internal format
    1, // Width
    1, // Height
    0, // Border
    gl.RGBA, // Source format
    gl.UNSIGNED_BYTE, // Source type
    pixels, // Data
  );

  return new Promise<WebGLTexture>((resolve, reject) => {
    // Load real texture
    const image = new Image();
    image.onload = () => {
      console.debug("gl :: texture :: onload");

      gl.bindTexture(gl.TEXTURE_2D, texture);
      gl.texImage2D(
        gl.TEXTURE_2D,
        0, // Mipmap Level
        gl.RGBA, // Internal format
        gl.RGBA, // Source format
        gl.UNSIGNED_BYTE, // Source type
        image,
      );

      // Use mipmaps or linear filtering
      if (isPowerOf2(image.width) && isPowerOf2(image.height)) {
        gl.generateMipmap(gl.TEXTURE_2D);
      } else {
        console.warn(
          "gl :: texture :: Texture size is not a power of two",
          textureUrl,
        );
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      }

      resolve(texture);
    };
    image.src = textureUrl;
  });
};

export const updateVideoTexture = (
  gl: WebGLRenderingContext,
  texture: WebGLTexture,
  videoElement: HTMLVideoElement,
) => {
  const level = 0;
  const internalFormat = gl.RGBA;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(
    gl.TEXTURE_2D,
    level,
    internalFormat,
    srcFormat,
    srcType,
    videoElement,
  );
};
