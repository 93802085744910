import { ImageButton } from "./ImageButton";

export interface FullScreenButtonProps {
  element: HTMLElement;
}

export const FullScreenButton = ({ element }: FullScreenButtonProps) => {
  const handleClick = () => {
    if (document.fullscreenElement) {
      void document.exitFullscreen();
    } else {
      void element.requestFullscreen({ navigationUI: "show" });
    }
  };

  return (
    <ImageButton imageUrl={"/images/full_screen.png"} onClick={handleClick} />
  );
};
