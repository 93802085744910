import Image from "react-bootstrap/Image";

export interface ImageButtonProps {
  imageUrl: string;
  onClick: () => void;
}

export const ImageButton = ({ imageUrl, onClick }: ImageButtonProps) => {
  return (
    <Image
      src={imageUrl}
      onClick={onClick}
      style={{
        width: "2.5rem",
        height: "2.5rem",
        cursor: "pointer",
        userSelect: "none",
      }}
    />
  );
};
