import { useEffect, useRef, useState } from "react";
import { createVideoTextureShader } from "../gl/shaders/video-texture-shader";
import { createGrayBackgroundShader } from "../gl/shaders/gray-background-shader";
import {
  createQuadPuzzle,
  Puzzle,
  PuzzlePiece,
  PuzzlePieceSize,
} from "../puzzle";
import { createPuzzleShader } from "../gl/shaders/puzzle-shader";
import { useNavigate } from "react-router-dom";
import { routes } from "../route";
import { convertRemToPixels } from "../style";
import { createPuzzleOutlineShader } from "../gl/shaders/puzzle-outline-shader";
import { playSound } from "../sound";
import { createJigsawMaskShader } from "../gl/shaders/jigsaw-mask-shader";
import { TopButtonToolbar } from "./TopButtonToolbar";
import { ImageButton } from "./ImageButton";
import { displayInterstitial } from "../ads";
import { loadSettings, mergeSettings } from "../persisted-settings";
import { shootConfetti } from "./ParticleCanvas";

const puzzlePieceSizeToRem = {
  small: 6,
  medium: 9,
  large: 16,
} as const satisfies Record<PuzzlePieceSize, number>;

const pullToFront = (puzzle: Puzzle, piece: PuzzlePiece) => {
  const index = puzzle.pieces.findIndex((p) => p === piece);

  if (index >= 0) {
    puzzle.pieces.splice(index, 1);
    puzzle.pieces.push(piece);
  }
};

const pushToBack = (puzzle: Puzzle, piece: PuzzlePiece) => {
  const index = puzzle.pieces.findIndex((p) => p === piece);

  if (index >= 0) {
    puzzle.pieces.splice(index, 1);
    puzzle.pieces.unshift(piece);
  }
};

export interface PuzzleCanvasProps {
  videoElement: HTMLVideoElement;
  puzzleSize: PuzzlePieceSize;
}

export const PuzzleCanvas = ({
  videoElement,
  puzzleSize,
}: PuzzleCanvasProps) => {
  const navigate = useNavigate();
  const [paused, setPaused] = useState(false);
  const [drawOutlines, setDrawOutlines] = useState(
    () => loadSettings().drawOutlines ?? false,
  );
  const drawOutlinesRef = useRef(drawOutlines);
  const [drawGrayBackground, setDrawGrayBackground] = useState(
    () => loadSettings().drawGrayBackground ?? true,
  );
  const drawGrayBackgroundRef = useRef(drawGrayBackground);
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement | null>(
    null,
  );

  useEffect(() => {
    if (!canvasElement) {
      return;
    }

    const gl = canvasElement.getContext("webgl");

    if (!gl) {
      return;
    }

    const pixelsPerPiece = convertRemToPixels(puzzlePieceSizeToRem[puzzleSize]);
    const columns = Math.ceil(gl.canvas.width / pixelsPerPiece) + 1;

    const rows = Math.ceil(gl.canvas.height / pixelsPerPiece) + 1;
    const puzzle = createQuadPuzzle(columns, rows, gl);

    let draggingPuzzlePiece: PuzzlePiece | null = null;
    let lastScreenX = 0;
    let lastScreenY = 0;

    // Event listeners
    const pointerDownListener = (e: PointerEvent) => {
      if (!e.isPrimary || e.button !== 0) {
        return false;
      }

      const x = e.offsetX;
      const y = canvasElement.clientHeight - e.offsetY;

      const halfPieceWidth = gl.canvas.width / puzzle.columns / 2.0;
      const halfPieceHeight = gl.canvas.height / puzzle.rows / 2.0;

      let piece: PuzzlePiece | null = null;

      for (let i = puzzle.pieces.length - 1; i >= 0; i--) {
        const p = puzzle.pieces[i];
        const pX1 = p.canvasX - halfPieceWidth;
        const pX2 = p.canvasX + halfPieceWidth;

        const pY1 = p.canvasY - halfPieceHeight;
        const pY2 = p.canvasY + halfPieceHeight;

        if (x >= pX1 && x <= pX2 && y >= pY1 && y <= pY2) {
          piece = p;
          break;
        }
      }

      if (piece && !piece.locked) {
        draggingPuzzlePiece = piece;
        lastScreenX = e.screenX;
        lastScreenY = e.screenY;
        pullToFront(puzzle, piece);
      }

      console.debug("PuzzleCanvas :: Pointer down listener", x, y, piece, e);
    };

    const pointerMoveListener = (e: PointerEvent) => {
      if (!e.isPrimary || !draggingPuzzlePiece) {
        return;
      }

      const diffX = e.screenX - lastScreenX;
      const diffY = e.screenY - lastScreenY;

      lastScreenX = e.screenX;
      lastScreenY = e.screenY;

      draggingPuzzlePiece.canvasX += diffX;
      draggingPuzzlePiece.canvasY -= diffY;
    };

    const pointerUpListener = (e: PointerEvent) => {
      e.preventDefault();

      if (!e.isPrimary || !draggingPuzzlePiece) {
        return;
      }

      const pixelMargin = convertRemToPixels(3);

      const halfPieceWidth = gl.canvas.width / puzzle.columns / 2.0;
      const halfPieceHeight = gl.canvas.height / puzzle.rows / 2.0;

      const targetX =
        (draggingPuzzlePiece.targetColumn / puzzle.columns) * gl.canvas.width +
        halfPieceWidth;
      const targetY =
        (draggingPuzzlePiece.targetRow / puzzle.rows) * gl.canvas.height +
        halfPieceHeight;

      const targetDiffX = Math.abs(targetX - draggingPuzzlePiece.canvasX);
      const targetDiffY = Math.abs(targetY - draggingPuzzlePiece.canvasY);

      if (targetDiffX < pixelMargin && targetDiffY < pixelMargin) {
        draggingPuzzlePiece.canvasX = targetX;
        draggingPuzzlePiece.canvasY = targetY;
        draggingPuzzlePiece.locked = true;
        pushToBack(puzzle, draggingPuzzlePiece);
        playSound("jigsaw_drop.mp3", { volume: 0.5 });
        shootConfetti(
          (100 * draggingPuzzlePiece.canvasX) / gl.canvas.width,
          100 - (100 * draggingPuzzlePiece.canvasY) / gl.canvas.height,
        );
      }

      draggingPuzzlePiece = null;

      if (puzzle.pieces.every((p) => p.locked)) {
        navigate(routes.home.render());
        displayInterstitial();
      }

      console.debug("PuzzleCanvas :: Pointer up listener", e);
    };

    const keyDownListener = (e: KeyboardEvent) => {
      console.debug("PuzzleCanvas :: Key down listener", e);

      if (e.key === "h") {
        puzzle.pieces.forEach((p) => {
          if (!p.locked) {
            p.visible = !p.visible;
          }
        });
      }
    };

    document.addEventListener("keydown", keyDownListener);
    document.addEventListener("pointerdown", pointerDownListener);
    document.addEventListener("pointermove", pointerMoveListener);
    document.addEventListener("pointerup", pointerUpListener);

    // Flip image pixels into the bottom-to-top order that WebGL expects.
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

    // Setup gl settings
    gl.disable(gl.DEPTH_TEST); // Disable depth testing
    gl.depthFunc(gl.LEQUAL); // Near things obscure far things
    gl.enable(gl.BLEND); // Enable blending
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA); // Define blending function

    // Setup shaders
    const videoTextureShader = createVideoTextureShader(gl, videoElement);
    const grayBackgroundShader = createGrayBackgroundShader(gl);
    const puzzleShader = createPuzzleShader(gl);
    const puzzleOutlineShader = createPuzzleOutlineShader(gl);
    const jigsawMaskShader = createJigsawMaskShader(gl);

    let pageTexturesPrepared = false;
    jigsawMaskShader
      .preparePageTextures(puzzle)
      .then(() => (pageTexturesPrepared = true));

    // Frame relevant variables
    let lastTimeInMilliseconds = 0;
    let loop = true;

    const renderFrame = (timeInMilliseconds: number = 0) => {
      const deltaSeconds = (timeInMilliseconds - lastTimeInMilliseconds) / 1000;

      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

      // Clear back buffer
      gl.clearDepth(1);
      gl.clearColor(0, 0, 0, 0);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      if (pageTexturesPrepared) {
        videoTextureShader.render();

        if (drawGrayBackgroundRef.current) {
          grayBackgroundShader.render(
            videoTextureShader.videoTexture,
            videoElement.videoWidth / videoElement.videoHeight,
          );
        }

        if (drawOutlinesRef.current) {
          puzzleOutlineShader.render(
            videoTextureShader.videoTexture,
            puzzle,
            true,
            draggingPuzzlePiece,
            jigsawMaskShader,
          );
        }

        puzzleShader.render(
          videoTextureShader.videoTexture,
          videoElement.videoWidth / videoElement.videoHeight,
          puzzle,
          true,
          draggingPuzzlePiece,
          jigsawMaskShader,
        );
        puzzleOutlineShader.render(
          videoTextureShader.videoTexture,
          puzzle,
          false,
          draggingPuzzlePiece,
          jigsawMaskShader,
        );
        puzzleShader.render(
          videoTextureShader.videoTexture,
          videoElement.videoWidth / videoElement.videoHeight,
          puzzle,
          false,
          draggingPuzzlePiece,
          jigsawMaskShader,
        );
      }

      // Request next frame
      if (loop) {
        lastTimeInMilliseconds = timeInMilliseconds;
        window.requestAnimationFrame(renderFrame);
      }
    };

    renderFrame();

    return () => {
      // Break render loop on unmount
      loop = false;

      document.removeEventListener("keydown", keyDownListener);
      document.removeEventListener("pointerdown", pointerDownListener);
      document.removeEventListener("pointermove", pointerMoveListener);
      document.removeEventListener("pointerup", pointerUpListener);
    };
  }, [canvasElement, videoElement]);

  return (
    <>
      <canvas
        width={canvasElement?.clientWidth}
        height={canvasElement?.clientHeight}
        ref={setCanvasElement}
        style={{
          width: "100%",
          height: "100%",
          zIndex: -1,
          touchAction: "none",
          backgroundImage: 'url("/images/background.jpg")',
          backgroundSize: "cover",
        }}
      />

      <TopButtonToolbar align={"center"}>
        <ImageButton
          imageUrl={"/images/rewind_10.png"}
          onClick={() => {
            const newCurrentTime = videoElement.currentTime - 10;
            videoElement.currentTime =
              newCurrentTime >= 0
                ? newCurrentTime
                : videoElement.duration + newCurrentTime;
          }}
        />
        <ImageButton
          imageUrl={paused ? "/images/play.png" : "/images/pause.png"}
          onClick={() => {
            if (paused) {
              void videoElement.play();
            } else {
              void videoElement.pause();
            }

            setPaused(!paused);
          }}
        />
        <ImageButton
          imageUrl={"/images/forward_10.png"}
          onClick={() => {
            const newCurrentTime = videoElement.currentTime + 10;
            videoElement.currentTime =
              newCurrentTime <= videoElement.duration
                ? newCurrentTime
                : newCurrentTime - videoElement.duration;
          }}
        />
      </TopButtonToolbar>
      <TopButtonToolbar align={"right"}>
        <ImageButton
          imageUrl={
            drawOutlines
              ? "/images/without_outlines.png"
              : "/images/outlines.png"
          }
          onClick={() => {
            drawOutlinesRef.current = !drawOutlines;
            setDrawOutlines(drawOutlinesRef.current);
            mergeSettings({
              drawOutlines: drawOutlinesRef.current,
            });
          }}
        />
        <ImageButton
          imageUrl={
            drawGrayBackground
              ? "/images/without_gray_background.png"
              : "/images/gray_background.png"
          }
          onClick={() => {
            drawGrayBackgroundRef.current = !drawGrayBackground;
            setDrawGrayBackground(drawGrayBackgroundRef.current);
            mergeSettings({
              drawGrayBackground: drawGrayBackgroundRef.current,
            });
          }}
        />
      </TopButtonToolbar>
    </>
  );
};
