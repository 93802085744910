import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import "@tsparticles/confetti";
import { confetti } from "@tsparticles/confetti";

const PARTICLES_ID = "tsParticles";

export const shootConfetti = (x: number, y: number) => {
  console.debug("shootConfetti", x, y);

  void confetti(PARTICLES_ID, {
    position: { x, y },
    spread: 360,
    startVelocity: 7,
    angle: 0,
    drift: 0,
    gravity: 0.25,
    flat: true,
    count: 13,
    ticks: 1000,
    shapes: ["circle", "square", "heart", "star"],
    shapeOptions: {},
  });
};

export const ParticleCanvas = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => setInitialized(true));
  }, []);

  return <>{initialized && <Particles id={PARTICLES_ID} />}</>;
};
