import { createTexture, updateVideoTexture } from "../texture";

export interface VideoTextureShader {
  render: () => void;
  videoTexture: WebGLTexture;
}

export const createVideoTextureShader = (
  gl: WebGLRenderingContext,
  videoElement: HTMLVideoElement,
): VideoTextureShader => {
  const videoTexture = createTexture(gl);

  const render = () => {
    updateVideoTexture(gl, videoTexture, videoElement);
  };

  return {
    videoTexture,
    render,
  };
};
