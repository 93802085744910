import { Mutex } from "async-mutex";

export interface SoundOptions {
  volume?: number;
  playbackRate?: number;
  onEnded?: () => void;
}

export const playSound = (file: string, options?: SoundOptions) => {
  console.debug("Sound :: Playing audio", file);

  const audio = new Audio(`/sounds/${file}`);
  audio.volume = options?.volume ?? 1.0;
  audio.playbackRate = options?.playbackRate ?? 1.0;
  audio.preservesPitch = false;

  if (options?.onEnded) {
    audio.addEventListener("ended", options.onEnded);
  }

  void audio.play();
};

const mutex = new Mutex();

export const playBackgroundSound = (file: string, volume?: number) => {
  void mutex.runExclusive(() => {
    console.debug(
      "Sound :: Play background sound",
      file,
      volume,
      window.backgroundAudio,
    );

    const effectiveVolume = volume ?? 1;

    if (window.backgroundAudio?.src === file) {
      window.backgroundAudio.volume = effectiveVolume;
      return;
    }

    if (window.backgroundAudio) {
      console.debug(
        "Sound :: Pausing stale background sound",
        file,
        volume,
        window.backgroundAudio,
      );
      window.backgroundAudio.pause();
    }

    const audio = new Audio(`/sounds/${file}`);
    audio.volume = effectiveVolume;
    audio.loop = true;
    audio.addEventListener("canplaythrough", () => {
      if (audio === window.backgroundAudio) {
        audio.play().catch((e) => {
          window.addEventListener("click", () => void audio.play(), {
            once: true,
          });
          window.addEventListener("touch", () => void audio.play(), {
            once: true,
          });
        });
      }
    });
    window.backgroundAudio = audio;
  });
};
