import { CSSProperties, PropsWithChildren } from "react";

type Align = "left" | "center" | "right";

const commonStyles: CSSProperties = {
  display: "flex",
  position: "absolute",
  gap: "1rem",
  top: "1rem",
};

const alignStyles: Record<Align, CSSProperties> = {
  left: {
    left: "1rem",
  },
  center: {
    left: "50%",
    transform: "translateX(-50%)",
  },
  right: {
    right: "1rem",
  },
};

export interface TopButtonToolbarProps {
  align?: "left" | "center" | "right";
}

export const TopButtonToolbar = ({
  children,
  align = "left",
}: PropsWithChildren<TopButtonToolbarProps>) => {
  return (
    <div
      style={{
        ...commonStyles,
        ...alignStyles[align],
      }}
    >
      {children}
    </div>
  );
};
